import React from "react";

export const Header = (props) => {
  return (
    <header id="header" style={{ marginTop: '52px' }}>
      <div className="intro">
        <img alt="" src="img/0001.jpg" className="img_style" />
      </div>
    </header>
  );
};
